import React from 'react'

const HSTSPixel = () => (
  <img
    src="/hsts.gif"
    data-test="hsts-pixel"
    alt="HSTS Pixel"
    role="presentation"
    width="1"
    height="1"
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'none',
      visibility: 'hidden',
    }}
  />
)

export default HSTSPixel
