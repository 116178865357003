import React from 'react'
// import PropTypes from 'prop-types'

import styles from './Footer.module.scss'

import Menu from '../Menu'
import IconMenu from '../IconMenu'
import Legal from '../Legal'
// import SignupForm from '../SignupForm'

import brandsContent from '../../content/brands'
import socialContent from '../../content/social'
import paymentsContent from '../../content/payments'

// function Footer({ hasMarketingEnabled }) {
function Footer() {
  return (
    <footer id="footer" className={styles.wrap}>
      {/* {hasMarketingEnabled && (
        <section className={styles.marketing}>
          <SignupForm />
        </section>
      )} */}
      <section className={styles.menu}>
        <div className={styles.container}>
          <Menu />
        </div>
      </section>
      <section className={styles.brands}>
        <div className={styles.container} data-testid="Footer/BrandsIconMenu">
          <IconMenu content={brandsContent} className="brandMenu" />
        </div>
      </section>
      <div className={styles.container}>
        <section className={styles.icons}>
          <div className={styles.iconGroup}>
            <IconMenu content={socialContent} className="social" />
          </div>
          <div className={styles.iconGroup}>
            <IconMenu content={paymentsContent} />
          </div>
        </section>
      </div>
      <section className={styles.legal}>
        <div className={styles.container}>
          <Legal />
        </div>
      </section>
    </footer>
  )
}

// Footer.propTypes = {
//   hasMarketingEnabled: PropTypes.bool,
// }

Footer.defaultProps = {
  hasMarketingEnabled: true,
}

Footer.getPageTypeProps = (query = {}) => {
  const { pageType = '' } = query
  switch (pageType) {
    case 'basket':
    case 'basketFulfilment':
      return { hasMarketingEnabled: false }
    default:
      return { hasMarketingEnabled: true }
  }
}

export default Footer
