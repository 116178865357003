import React, { useState, useEffect, useRef, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import { Bag, Account, Search as SearchIcon } from '../../../common/components/Icons'
import ErrorBoundary from '../../../common/components/ErrorBoundary'
import HSTSPixel from '../../../common/components/HSTSPixel'

import fetchUserInfo from '../../../common/utils/fetchUserInfo'
import Logo from '../Logo'
import TouchNavigationDrawer from '../TouchNavigationDrawer'
import MegaMenu from '../MegaMenu'
import HeaderWrap from '../HeaderWrap'
import NavMenu from '../NavMenu'
import Search from '../Search'
import SearchPopup from '../SearchPopup'
import Badge from '../Badge'
import AccountModal from '../AccountModal/AccountModal'
import useBasketCount from '../../hooks/useBasketCount'
import PropBar from '../PropBar'

import styles from './Header.module.scss'
import getMegaNav, { addShopAllItems } from '../../providers/megaNav'
import getLogoVariant from '../../providers/logoVariant'
import getPropBar from '../../providers/propBar'
import toSlug from '../../../common/utils/toSlug'

import { loadImpressionFlag, LOGO_URL, createTrackingEvent } from './analytics'

const TOOLS = {
  SEARCH: 'SEARCH',
  BASKET: 'BASKET',
  USER: 'USER',
}

function getToolIndex(currentTool, tool) {
  return { zIndex: currentTool === tool ? -2 : -3 }
}

function getBadgeText(count) {
  const value = parseInt(count, 10)

  if (Number.isNaN(value) || value === 0) {
    return ''
  }

  return value.toString()
}

function Header({ menuItems, menuItemsTouchNav, logoVariant, propBar, hasPropBarEnabled }) {
  const backgroundNode = useRef(null)
  const [currentTool, setCurrentToolState] = useState(null)
  const [isAccountModalOpen, setIsAccountModalOpen] = React.useState(false)

  const customerLinks = [
    {
      title: 'Customer Service',
      link: '/help/contactus',
      id: 'root-extra-1',
    },
    {
      title: 'Account',
      onClick: () => setIsAccountModalOpen(true),
      id: 'root-extra-2',
    },
  ]

  // NOTE: Tu Migration to Argos APIs
  const { basketCount, retrieveBasketCount } = useBasketCount()

  // Add a flag to the digitalData object, when the client executes this module
  useEffect(() => loadImpressionFlag(), [])

  useEffect(() => {
    fetchUserInfo()
    retrieveBasketCount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps -- Either request should only be triggered once at loading time

  const menuItemsTouchNavWithTracking = useMemo(
    () =>
      menuItemsTouchNav.map(item => ({
        ...item,
        onClick: createTrackingEvent('click', { label: `nav:${toSlug(item.title)}` }),
        items: item.items.map(subItem => ({
          ...subItem,
          onClick: createTrackingEvent('click', { label: `nav:${toSlug(subItem.title)}` }),
        })),
      })),
    [menuItemsTouchNav]
  )

  const setCurrentTool = (targetTool, track = true) => {
    const toolMap = {
      SEARCH: 'search',
      BASKET: 'miniBasket',
      USER: 'myAccount',
    }
    const tool = currentTool === targetTool ? null : targetTool
    const label = tool === null ? `${toolMap[currentTool]}:close` : `${toolMap[targetTool]}:open`
    const sendTrackingEvent = createTrackingEvent('click', { label })

    if (track) {
      sendTrackingEvent()
    }

    setCurrentToolState(tool)
  }
  /**
   * Debounce calls to setCurrentTool by tiny amount
   * This avoids bugs where the tools close-on-clickAway behaviour conflicts
   * with the tools toggle-onClick-handlers which made closing tool by
   * clicking it's own icon impossible
   */
  const setCurrentToolThrottled = debounce(setCurrentTool, 5)
  const toggleTool = targetTool => () => setCurrentToolThrottled(targetTool)
  const closeTool = () => setCurrentToolThrottled(null)

  const contextualCustomerLinks = [...customerLinks]

  const cartCloseTimeout = useRef(null)
  useEffect(() => {
    function onCartUpdate() {
      retrieveBasketCount()
    }
    window.addEventListener('cartupdate', onCartUpdate)
    return () => {
      if (cartCloseTimeout.current) {
        clearTimeout(cartCloseTimeout.current)
        cartCloseTimeout.current = null
      }
      window.removeEventListener('cartupdate', onCartUpdate)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps -- Adding the event listener should only be done once at loading time

  function renderMegaMenu({ items, columns, content, useColumns }) {
    if (!items || !items.length) return null

    let contentColumn = null
    if (content) {
      contentColumn = (
        <a href={content.link} className={styles.content}>
          <img src={content.img} alt={content.alt} />
        </a>
      )
    }

    if (useColumns) {
      return <MegaMenu className={styles.submenu} columns={columns} contentColumn={contentColumn} />
    }

    return <MegaMenu className={styles.submenu} items={items} contentColumn={contentColumn} />
  }

  const renderBadge = (badgeCount, ariaText) => (
    <Badge text={badgeCount}>
      <Bag label="View mini basket" />
      <span className={styles.toolLabel} aria-hidden>
        Basket
      </span>
      <span className="u-screen-reader-text">{ariaText}</span>
    </Badge>
  )
  const badgeBasketCount = getBadgeText(basketCount)

  const isPropBarEnabled = hasPropBarEnabled && !!propBar

  return (
    <>
      <div className={styles.overlays} ref={backgroundNode} />
      <HeaderWrap className={styles.header}>
        <TouchNavigationDrawer
          className={styles.drawer}
          items={[...menuItemsTouchNavWithTracking, ...contextualCustomerLinks]}
          accountModalIsActive={isAccountModalOpen}
          getBackgroundNode={() => backgroundNode.current}
          onBurgerToggle={isOpen => createTrackingEvent('click', { label: `nav:${isOpen ? 'open' : 'close'}` })()}
          onBackClick={createTrackingEvent('click', { label: 'nav:back' })}
        />
        <div className={styles.logo}>
          <a href={LOGO_URL} data-testid="Header/Logo">
            <span className={styles.logoSmall}>
              <Logo height={24} variant={logoVariant} />
            </span>
            <span className={styles.logoLarge}>
              <Logo height={30} variant={logoVariant} />
            </span>
          </a>
        </div>
        <div className={styles.search}>
          <Search />
        </div>
        <NavMenu className={styles.toolbar}>
          <button
            className={`${styles.tool} ${styles.toolSearch}`}
            type="button"
            onClick={toggleTool(TOOLS.SEARCH)}
            data-testid="Header/ToolSearch"
          >
            <SearchIcon label="Search" />
            <span className={styles.toolLabel} aria-hidden>
              Search
            </span>
            <span className="u-screen-reader-text">Toggle Search Window</span>
          </button>
          <button
            type="button"
            onClick={() => setIsAccountModalOpen(true)}
            className={`${styles.tool} ${styles.toolLink}`}
            data-testid="Header/Account"
          >
            <Account label="Account" />
            <span className={styles.toolLabel} aria-hidden>
              Account
            </span>
            <span className="u-screen-reader-text">Toggle Account Modal</span>
          </button>

          <a href="/basket" className={`${styles.tool} ${styles.toolLink}`} data-testid="Header/ToolMiniBasket">
            {renderBadge(badgeBasketCount, 'Redirects to Basket')}
          </a>
        </NavMenu>
        <div className={styles.toolPopup} style={getToolIndex(currentTool, TOOLS.SEARCH)}>
          <SearchPopup isOpen={currentTool === TOOLS.SEARCH} onClose={closeTool} className={styles.searchPopup} />
        </div>

        <NavMenu className={styles.menu} data-testid="Header/Nav">
          {menuItems.map(item => (
            <Fragment key={item.id}>
              <a className={styles.navLink} href={item.link}>
                {item.title}
              </a>
              {renderMegaMenu(item)}
            </Fragment>
          ))}
        </NavMenu>
        <AccountModal isOpen={isAccountModalOpen} onClose={() => setIsAccountModalOpen(false)} />
        <HSTSPixel />
      </HeaderWrap>
      <div className={styles.placeholder} />
      <ErrorBoundary>
        {isPropBarEnabled && (
          <div className={styles.propBarWrapper}>
            <PropBar {...propBar} />
          </div>
        )}
      </ErrorBoundary>
    </>
  )
}

Header.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  menuItemsTouchNav: PropTypes.arrayOf(PropTypes.shape({})),
  logoVariant: PropTypes.string,
  propBar: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    colour: PropTypes.string,
  }),
  hasPropBarEnabled: PropTypes.bool,
}

Header.defaultProps = {
  menuItems: [],
  menuItemsTouchNav: [],
  logoVariant: 'default',
  propBar: null,
  hasPropBarEnabled: true,
}

Header.getPageTypeProps = (query = {}) => {
  const { pageType = '' } = query
  switch (pageType) {
    case 'basket':
    case 'basketFulfilment':
    case 'checkout':
      return { hasPropBarEnabled: false }
    default:
      return { hasPropBarEnabled: true }
  }
}

Header.getInitialProps = async function getInitialProps() {
  const menuItems = await getMegaNav()
  const menuItemsTouchNav = addShopAllItems(JSON.parse(JSON.stringify(menuItems)))
  const logoVariant = getLogoVariant(new Date())
  const propBar = await getPropBar(new Date())

  return { menuItems, menuItemsTouchNav, logoVariant, propBar }
}

export default Header
