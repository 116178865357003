export default () => [
  {
    from: new Date('11 December 2024 08:00:00 UTC'),
    content: {
      text: '20% off selected kids & baby clothing. Ends 23:59 on 15th December. ',
      link: '/list/shop-selected-kids-and-baby-promo?tag=tu:propbar-kidspromo',
      colour: 'black',
    },
  },
  {
    from: new Date('16 December 2024 00:00:00 UTC'),
    content: {
      text: 'Order by 6pm on 19th December for click & collect in store & Get 5x Nectar points ',
      link: '/events/christmas?tag=tu:propbar-ccdelivery-nectar',
      colour: 'black',
    },
  },
  {
    from: new Date('20 December 2024 00:00:00 UTC'),
    content: {
      text: 'Order by 6pm on 22nd December for next day delivery for Christmas & Up to 50% off Sale! ',
      link: '/list/shop-all-tu-clothing-sale/?tag=tu:propbar-nextdaydelivery-sale',
      colour: 'red',
    },
  },
  {
    from: new Date('23 December 2024 00:00:00 UTC'),
    content: {
      text: 'Up to 50% off sale! ',
      linkText: 'Shop here',
      link: '/list/shop-all-tu-clothing-sale/?tag=tu:propbar-sale',
      colour: 'red',
    },
  },
  // base propbar
  {
    from: new Date('31 December 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
